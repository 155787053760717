html,
body,
#root
{
    position: fixed;
    top: 0;
    left: 0;   
    width: 100%;
    height: 100%;
    background: #121212;    
}

canvas{
    touch-action: none;    
}
a{
  color: inherit;
  text-decoration: none; 
}
/* Button */
.button1 {
  background-color: #e7e7e7; 
  color: black;
  border: none;
  
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 30px;
}
.button1:hover {
  background-color: #4CAF50; 
  color: white;
}


/* HOME */
/* .home-container > div:nth-child(1n) {
  background-color: #96ceb4;	
  }
  
.home-container > div:nth-child(3n) {
background-color: #88d8b0;
}

.home-container > div:nth-child(2n) {
    background-color: #ff6f69;
}

.home-container > div:nth-child(4n) {
    background-color: #ffcc5c;
}  */
.home-container{
  height: 100%;  
  width: 100%;
  display: grid;
  grid-template-columns: repeat(12, 1fr);  
  grid-template-rows: 10vh auto 10vh;
  color: aliceblue;
  /* background-color: transparent;   */
  position: fixed;
  z-index: 100;
}



.bio{
  grid-column: 1 / 4;
  font-size: 1.125rem;  
  padding: 50px;
  font-family: 'Open Sans', sans-serif;
  line-height: 1.6;
  display: flex;
  align-items: center;
}
.model-home{
  grid-column: 4 / 10;
  display: flex;
  align-items: center;
  justify-content: center;  
  opacity: 0;
}
.socials{
  grid-column: 10 / -1;
  font-size: 0.8125rem;    
  font-family: 'Open Sans', sans-serif;  
  font-weight: 500;
  color: gray;    
  writing-mode: vertical-rl;      
  align-items: start;    
  padding: 30px;
}


.sidebar{
  /* background-color: #ffeead; */
  display: flex;
  flex: 1;
  justify-content: space-around;  
  transform: rotate(-180deg);    
}
ul{
  list-style: none;
  padding-inline-start: 0;
}


/* Contact */
/* .contact-container > div:nth-child(1n) {
  background-color: #96ceb4;	
  }
  
.contact-container > div:nth-child(3n) {
background-color: #88d8b0;
}

.contact-container > div:nth-child(2n) {
    background-color: #ff6f69;
}

.contact-container > div:nth-child(4n) {
    background-color: #ffcc5c;
}  */
.contact-container {
  display: grid;
  height: 100%;
  width: 100%;
  grid-gap: 3px;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: 10vh auto 10vh;
  font-family: 'Open Sans', sans-serif;  
  color: aliceblue;
  position: absolute;
  z-index: 100;  
}


.header {
  display: flex;
  width: 100%;
  height: 10vh;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  font-family: 'Open Sans', sans-serif;  
  font-weight: 700;
  z-index: 101;
  position: absolute;
  color: white;
  background-color: transparent;
  
}
.contactButton{
  padding-right: 40px;
}

.logo{
  padding-left: 40px;
}
button {
  all: unset;
  cursor: pointer;
}


.model {  
  grid-column: 1 / 6;
}

.content {
grid-column: 6 / -1;
display: grid;
grid-gap: 3px;
grid-template-columns: 1fr;
grid-template-rows: 1fr 1fr 1fr;
}

.footer {
  grid-column: 1 / -1;
  display: flex;
  justify-content: center;  
  font-family: 'Open Sans', sans-serif;  
  font-weight: 700;
}

.row1{
/* background-color: #4CAF50;     */
display: flex;
align-items: center;
}

.row2{
/* background-color: #ff6f69; */
display: flex;
align-items: center;
}

.row3{
  /* background-color: #ffcc5c; */
  padding-top: 30px;
  }
.contact-socials{
  display: flex;
  flex: 1;
  justify-content: space-around;  
}
.bigText{
  font-size: 2.5rem;
  font-weight: 800;
}
.smallText, .smallTextDesktop{
  font-size: 16px;
  font-weight: 400;
  color: gray;
  padding-top: 10px;
}

.mobileText{
  display: none;
}
.links{
  font-weight: 600;
  font-size: 1.75rem;
  letter-spacing: .5px;
  line-height: 1.21429;
}

/* Responsive */
@media (max-width: 600px){
  
  .bio{
    grid-column: 1 / -1;  
    font-size: 1rem;
    align-items: start;
    padding-top: 10px;
    display: block;
    text-align: center;
  }
  .model-home{
    grid-column: 1 / -1;
  }
  .socials{
    visibility: hidden;
    grid-column: 1 / -1;
  }
}

@media (max-width:600px){
  .model,
  .content
  {
    grid-column: 1/-1 ;
  }
  .links{
    font-size: 1.125rem;
    padding: 20px;
  }
  .bigText{
    display: none;
  }
  .contact-container{
    grid-template-rows: 10vh 40vh 40vh 10vh;
  }
  .row1, .row2{
    justify-content: center;
  }
  .row3{
    display: flex;
    justify-content: center;
  }

  .smallText{
    text-align: center;
  }
  .smallTextDesktop{
    display: none;
  }
  .mobileText{
    font-size: 16px;
    font-weight: 400;
    color: gray;
    padding-top: 10px;
    display: block;
    text-align: center;
  }
  
}